import {
  ApplicationInsights,
  ICustomProperties,
  IEventTelemetry,
} from "@microsoft/applicationinsights-web";
import env from "../utils/env";

interface ServiceInfo {
  name: string;
  consent: {
    status: boolean;
  };
}

declare global {
  // define user centrics object (only needed subset) on window as it is defined, when the library is loaded.
  interface Window {
    __ucCmp?: {
      isInitialized: () => boolean;
      cmpController: {
        getServices: () => Promise<ServiceInfo[]>;
      };
    };
  }
}

export type IAppInsightsUserData = {
  orderSystem: string;
  customerNumber: number;
};

const appInsightsConnectionString =
  env.PORTAL_SDK_COMMON_APP_INSIGHTS_CONNECTIONSTRING;

const appInsightsOriginal = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
    enableAutoRouteTracking: true,
  },
});

let appInsightsLoaded = false;

async function isAppInsightsAccepted() {
  if (
    window.__ucCmp &&
    window.__ucCmp.isInitialized() &&
    window.__ucCmp?.cmpController?.getServices
  ) {
    // id must match the ID from app insights management panel
    const appInsightsName = "Azure Application Insights";
    const appInsightsAccepted = await window.__ucCmp.cmpController

      .getServices()
      .then((services) => {
        return services.find((item) => item[1].name === appInsightsName)[1]
          .consent.given;
      });

    if (!appInsightsLoaded && !!appInsightsAccepted) {
      appInsightsOriginal.loadAppInsights();
      appInsightsLoaded = true;
    }
    return !!appInsightsAccepted;
  }
  return false;
}

function onUserCentricsLibraryDefined() {
  if (!isAppInsightsAccepted()) {
    return;
  }
  if (!appInsightsLoaded) {
    appInsightsOriginal.loadAppInsights();
    appInsightsLoaded = true;
  }
}
isAppInsightsAccepted();

// proxy which forwards the calls to the original appInsights, when it's accepted by the user.
export const appInsights = {
  trackEvent: (
    event: IEventTelemetry,
    customProperties?: ICustomProperties
  ) => {
    if (isAppInsightsAccepted()) {
      appInsightsOriginal.trackEvent(event, customProperties);
    }
  },
};

export function setAppInsightsUser(userData: IAppInsightsUserData) {
  if (isAppInsightsAccepted()) {
    appInsightsOriginal.config.accountId = userData.customerNumber.toString();
    appInsightsOriginal.addTelemetryInitializer((envelope) => {
      envelope.tags["ai.cloud.role"] = userData.orderSystem;
      envelope.tags["ai.cloud.roleInstance"] =
        userData.customerNumber.toString();
    });
    appInsightsOriginal.trackPageView();
  }
}

export default {
  appInsights,
  setAppInsightsUser,
};
